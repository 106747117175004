<template scoped>
  <div>
    <el-form-item
      :rules="rules.read"
      label="Read"
      prop="read.type"
      :style="{ marginTop: '10px' }"
    >
      <el-select
        @change="type_change"
        v-model="logic.read.type"
        :style="{ width: '100%' }"
        placeholder="Select the Action"
      >
        <el-option
          v-for="item in supportRead"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <component :is="content" :logic="logic" :endpoint="endpoint"></component>
  </div>
</template>

<script>
export default {
  props: {
    logic: Object,
    endpoint: String,
  },
  created: function () {
    if (this.logic.read.type) {
      this.type_change(this.logic.read.type);
    }
  },
  computed: {},
  data: function () {
    return {
      content: null,
      supportRead: [
        {
          name: "Command Match",
          value: "Match",
        },
        {
          name: "Until Command Match",
          value: "UntilMatch",
        },
      ],
      rules: {
        read: [{ validator: this.validator_read, trigger: "blur" }],
      },
    };
  },
  methods: {
    validator_read: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the read type should not be empty"));
      } else {
        callback();
      }
    },
    type_change: function (val) {
      switch (val) {
        case "Match":
          this.content = () =>
            import("./Match/Panel");
          break;
        case "UntilMatch":
          this.content = () =>
            import("./UntilMatch/Panel");
          break;
        default:
          this.content = null;
          break;
      }
    },
  },
};
</script>